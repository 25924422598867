import Typing from "../design-system/Typing";
import ProductTimeline from "./ProductTimeline";

export default function Registration() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Typing text="DB Delay" />

      <ProductTimeline />
    </div>
  );
}
