"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTrainJourneyById = getTrainJourneyById;
exports.getTrainColorByCategory = getTrainColorByCategory;
var connection_1 = require("./connection");
var HttpClient_1 = require("./HttpClient");
/**
 * Get details of a single train journey
 * @param {string} id the journey id of a train
 * @returns {Partial<Train>} a partial train objects, that a not by default in a train requested in a journey search
 */
function getTrainJourneyById(id) {
    return __awaiter(this, void 0, void 0, function () {
        var client, url, data;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    client = new HttpClient_1.HttpClient();
                    url = "https://www.bahn.de/web/api/reiseloesung/fahrt";
                    return [4 /*yield*/, client.get("".concat(url, "?journeyId=").concat(encodeURIComponent(id)))];
                case 1:
                    data = (_b.sent()).data;
                    if (data == undefined)
                        return [2 /*return*/, {}];
                    return [2 /*return*/, {
                            arrivalTime: new Date(data.ankunftsZeitpunkt),
                            departureTime: new Date(data.abfahrtsZeitpunkt),
                            category: (0, connection_1.parseTrainCategory)((_a = data.halte[0].kategorie) !== null && _a !== void 0 ? _a : "ICE"),
                            destination: data.halte[data.halte.length - 1].name,
                            from: data.halte[0].name,
                            fromId: data.halte[0].extId,
                            to: data.halte[data.halte.length - 1].name,
                            toId: data.halte[data.halte.length - 1].extId,
                            id: parseId(data),
                        }];
            }
        });
    });
}
function parseId(data) {
    if (data.halte[0].nummer) {
        return data.halte[0].nummer;
    }
    else if (data.zugName !== null) {
        if (data.zugName.includes(" ") && data.zugName.split(" ")[1] !== "0") {
            return data.zugName.split(" ")[1];
        }
        else {
            var match = data.zugName.match(/(\D+)(\d+)/);
            if (match) {
                var _a = match.slice(1), _ = _a[0], number = _a[1];
                return number;
            }
        }
    }
    return "-1";
}
/**
 * Function to get the color of a train by its category
 * The colors where fetched from the DB Website using the Firefox Dev Tools (NO WARRANTY that they are correct)
 * @param category The category of the train
 * @returns The color of the train
 */
function getTrainColorByCategory(category, dark) {
    if (dark === void 0) { dark = false; }
    switch (category) {
        case "ICE":
        case "RJX":
        case "TGV":
        case "RJ":
        case "EST":
        case "IXB":
        case "RHI":
        case "DPF":
        case "DPX":
        case "OGV":
        case "RHT":
        case "RRT":
        case "THA":
            return dark ? "#f0f1f4" : "#282d37";
        case "IC":
        case "EC":
        case "EN":
        case "NJ":
            return dark ? "#aeb3bb" : "#646973";
        case "RE":
        case "IRE":
        case "FLX":
        case "SEV":
        case "DPN":
            return dark ? "#868b95" : "#878c96";
        case "RB":
            return dark ? "#868b95" : "#afb4bb";
        case "S":
            return dark ? "#297130" : "#408335";
        case "U":
            return "#375fc3";
        case "BUS":
            return dark ? "#804796" : "#814997";
        case "Tram":
        case "STR":
        case "SWB":
            return dark ? "#a8435c" : "#a9455d";
        case "SCH":
            return dark ? "#309ecf" : "#309fd1";
        case "AST":
            return dark ? "#fed600" : "#ffc800";
        default:
            return "#646973";
    }
}
