import { IJourneyDetailed, IJourneyPreview } from "@jozys/db-delay-types";
import { JourneyPagination } from "../hooks/JourneyProvider";

export type JourneyContextType = {
  journeys: JourneyPagination[];
  fetchJourneys: (page?: number) => Promise<IJourneyPreview[]>;
  getLastJourney: () => IJourneyPreview | null;
  getJourneyById: (id: string) => Promise<IJourneyDetailed>;
  deleteJourneyById: (id: string) => Promise<boolean>;
  getPageAmount: (amountPerPage: number) => Promise<number>;
};

export const JourneyContextDefault: JourneyContextType = {
  journeys: [],
  fetchJourneys: () => new Promise(() => null),
  getLastJourney: () => null,
  getJourneyById: () => new Promise(() => null),
  deleteJourneyById: () => new Promise(() => null),
  getPageAmount: () => new Promise(() => null),
};
