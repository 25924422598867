import { Commute, Settings, Train, Tram } from "@mui/icons-material";
import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../core/design-system/Button";
import JourneyProvider from "../hooks/JourneyProvider";
import useJourneyFilter from "../hooks/useJourneyFilter";
import CategoryMenu from "./CategoryMenu";
import Journeys, { Owner } from "./Journeys";
import { JourneyCategory } from "../interfaces/JourneyConfig";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function JourneysTab() {
  const journeyFilter = useJourneyFilter();
  const { t } = useTranslation();
  const [contextMenu, setContextMenu] = useState<boolean>(false);
  const theme = useTheme();

  const getDefaultTab = () => {
    switch (journeyFilter.getOwnerType() as Owner) {
      case "ALONE":
        return 0;
      case "SHARED":
        return 1;
      case "ALL":
        return 2;
      default:
        return 2;
    }
  };
  const [settingsAnchor, setSettingsAnchor] = useState<
    HTMLDivElement | undefined
  >();
  const [currentTab, setCurrentTab] = useState<number>(2);

  const getTrainIcon = () => {
    switch (journeyFilter.getCategory()) {
      case JourneyCategory.ALL:
        return <Commute />;
      case JourneyCategory.LONG_DISTANCE:
        return <Train />;
      case JourneyCategory.REGIONAL:
        return <Tram />;
      case null:
        return <Settings />;
    }
  };

  React.useEffect(() => {
    setCurrentTab(getDefaultTab());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeyFilter.getOwnerType()]);

  return (
    <Box sx={{ background: theme.palette.background.paper }}>
      <Tabs
        value={currentTab}
        onChange={(_event: React.SyntheticEvent, value: number) => {
          setCurrentTab(value);
          journeyFilter.setOwnerType(
            value === 0 ? "ALONE" : value === 1 ? "SHARED" : "ALL"
          );
        }}
        textColor="secondary"
        indicatorColor="secondary"
        allowScrollButtonsMobile
        variant={window.innerWidth < 600 ? "scrollable" : "standard"}
      >
        <Tab label={t("journeys.titleOwn")} value={0}></Tab>
        <Tab label={t("journeys.titleShared")} value={1}></Tab>
        <Tab label={t("journeys.titleAll")} value={2}></Tab>

        <Button
          startIcon={getTrainIcon()}
          onClick={(event) => {
            setContextMenu(!contextMenu);
            setSettingsAnchor(event?.currentTarget ?? null);
          }}
        >
          <Typography sx={{ textWrap: "balance" }} color="primary">
            {t(
              `journeys.configuration.${
                journeyFilter.getCategory()
                  ? `types.${journeyFilter.getCategory()}`
                  : "button"
              }`
            )}
          </Typography>
        </Button>
      </Tabs>

      <CategoryMenu
        anchorElement={settingsAnchor}
        open={contextMenu}
        selectedCategory={journeyFilter.getCategory()}
        onClose={() => setContextMenu(false)}
        onChange={(val) => {
          journeyFilter.setCategory(val);
          setContextMenu(false);
        }}
      />
      <TabPanel value={currentTab} index={0}>
        <JourneyProvider category={journeyFilter.getCategory()} owner="ALONE">
          <Journeys category={journeyFilter.getCategory()} type="ALONE" />
        </JourneyProvider>
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <JourneyProvider category={journeyFilter.getCategory()} owner="SHARED">
          <Journeys category={journeyFilter.getCategory()} type="SHARED" />
        </JourneyProvider>
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <JourneyProvider category={journeyFilter.getCategory()} owner="ALL">
          <Journeys category={journeyFilter.getCategory()} type="ALL" />
        </JourneyProvider>
      </TabPanel>
    </Box>
  );
}
