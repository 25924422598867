import { IntermediateStop } from "@jozys/db-api-wrapper/dist/src/connection";
import {
  Connection,
  Train,
} from "@jozys/db-api-wrapper/dist/src/types/connection";
import { Station } from "@jozys/db-api-wrapper/dist/src/types/station";
import { IDelayData, ITrainJourney } from "@jozys/db-delay-types";
import {
  DepartureBoard,
  Error,
  MoreVert,
  Search,
  SwapHoriz,
  SwapVert,
  Train as TrainIcon,
  Tram as TramIcon,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Container,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../../auth/hooks/useAuth";
import LoadingWrapper from "../../core/components/LoadingWrapper";
import Button from "../../core/design-system/Button";
import IconText from "../../core/design-system/IconText";
import JourneyPreview from "../../journey/components/Journey";
import searchDBJourney from "../../journey/utils/searchDBJourney";
import StationSearch from "../../station/components/StationSearch";
import StepHeader from "../design-system/StepHeader";
import AddIntermediateStopsMenu from "./AddIntermediateStopsMenu";
import VehicleSelection from "./VehicleSelection";

export interface FindJourneyProps {
  journeys: Connection[];
  setJourneys: (journeys: Connection[]) => void;
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  startStation: Station | undefined;
  endStation: Station | undefined;
  setStartStation: (station: Station | undefined) => void;
  setEndStation: (station: Station | undefined) => void;
  intermediateStops: IntermediateStop[];
  setIntermediateStops: (stops: IntermediateStop[]) => void;
}

export default function FindJourney(props: FindJourneyProps) {
  const auth = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [datetime, setDateTime] = React.useState<moment.Moment | null>(
    moment(Date.now())
  );

  const [showNoContent, setShowNoContent] = React.useState(false);

  const [vehicleMenuActive, setVehicleMenu] = React.useState(false);

  let vehicleSelectionValues = new Array(Object.keys(VehicleSelection).length);
  vehicleSelectionValues.fill(true);
  const [vehicleSelection, setVehicleSelection] = React.useState(
    vehicleSelectionValues
  );

  const { journeys } = props;
  const handleJourneySearch = () => {
    setErrors([]);
    const currentErrors = [];
    if (props.startStation === undefined || props.startStation === null) {
      currentErrors.push("from");
    }
    if (props.endStation === undefined || props.endStation === null) {
      currentErrors.push("to");
    }
    setErrors(currentErrors);
    if (currentErrors.length > 0) {
      return;
    }
    setLoading(true);
    searchDBJourney(
      props.startStation!.id,
      props.endStation!.id,
      datetime!.utc(true).toDate(),
      auth.getToken() ?? "",
      props.intermediateStops,
      0,
      vehicleSelection
    )
      .then((data) => {
        data = data.map((d) => {
          if (
            d.trains.some((t) => t.category === "TRANSFER") ||
            d.trains.some((t) => t.category === "WALK")
          ) {
            d.trains = d.trains.filter((t) => t.category !== "TRANSFER");
            d.trains = d.trains.filter((t) => t.category !== "WALK");
          }
          return d;
        });
        props.setJourneys(data);
        if (data.length === 0) {
          setShowNoContent(true);
        }
      })
      .catch(() => {
        setShowNoContent(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleJourneyChoose = (index: number) => {
    if (props.selectedIndex === index) {
      props.setSelectedIndex(-1);
    } else {
      props.setSelectedIndex(index);
    }
  };
  const theme = useTheme();
  const [errors, setErrors] = React.useState<string[]>([]);

  const renderVehicleButtons = (vehicles: boolean[]) => {
    const buttons: React.ReactNode[] = [];
    let keys = Object.keys(VehicleSelection);
    let values = Object.values(VehicleSelection);

    keys.forEach((oneKey, i) => {
      const value = values[i];
      buttons.push(
        <Button
          key={`${oneKey}-button`}
          sx={{
            color: vehicles[i]
              ? theme.palette.getContrastText(theme.palette.primary.main)
              : theme.palette.text.primary,
            borderColor: theme.palette.text.primary,
          }}
          variant={vehicles[i] ? "contained" : "outlined"}
          startIcon={
            typeof values[i].icon == "string" ? (
              <div
                key={`${oneKey}-icon`}
                style={{
                  border: "solid",
                  color: theme.palette.getContrastText(
                    theme.palette.primary.main
                  ),
                  borderRadius: "50%",
                  width: "1.25em",
                  fontSize: "0.9em",
                  height: "1.25em",
                  lineHeight: 1,
                  borderWidth: "2px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <b>{values[i].icon}</b>
              </div>
            ) : (
              <value.icon
                key={`${oneKey}-icon`}
                sx={{
                  color: vehicles[i]
                    ? theme.palette.getContrastText(theme.palette.primary.main)
                    : theme.palette.text.primary,
                }}
              />
            )
          }
          onClick={() => {
            let values = [...vehicleSelection];
            values[i] = !vehicleSelection[i];
            setVehicleSelection(values);
          }}
        >
          {t(values[i].name)}
        </Button>
      );
    });
    return buttons;
  };

  const setAllVehicles = () => {
    setVehicleSelection(new Array(vehicleSelection.length).fill(true));
  };

  const setRegionalTrains = () => {
    const nArray = new Array(vehicleSelection.length).fill(true);
    for (let i = 0; i < 2; i++) {
      nArray[i] = false;
    }
    setVehicleSelection(nArray);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Card
        sx={{
          flex: 1,
          background: theme.palette.background.default,
          width: window.innerWidth < 1000 ? "90%" : "100%",
        }}
      >
        <StepHeader text={t("journeys.creation.journey.findAndChoose")} />
        <CardContent
          sx={{
            display: "flex",
            alignContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: window.innerWidth < 1000 ? "column" : "row",
            }}
          >
            <StationSearch
              required
              error={errors.includes("from")}
              value={props.startStation}
              sx={{ display: "flex", flex: 10 }}
              label={t("journeys.creation.journey.from")}
              onValueChange={(_event, value) => {
                props.setStartStation(value as Station);
              }}
            />
            <IconButton
              disabled
              onClick={() => {
                props.setEndStation(props.startStation);
                props.setStartStation(props.endStation);
              }}
              sx={{
                display: "flex",
                flex: 1,
                alignSelf: "center",
              }}
            >
              {window.innerWidth < 1000 ? (
                <SwapVert fontSize="large" />
              ) : (
                <SwapHoriz fontSize="large" />
              )}
            </IconButton>
            <StationSearch
              error={errors.includes("to")}
              required
              value={props.endStation}
              sx={{ display: "flex", flex: 10 }}
              label={t("journeys.creation.journey.to")}
              onValueChange={(_event, value) => {
                props.setEndStation(value as Station);
              }}
            />
            <Box
              sx={{
                flex: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                maxWidth: window.innerWidth < 1000 ? "100%" : "30%",
                mt: window.innerWidth < 1000 ? 1 : 0,
              }}
            >
              <Button
                sx={{ flex: 1, ml: window.innerWidth < 1000 ? 0 : 1 }}
                variant="contained"
                startIcon={<Search />}
                onClick={() => handleJourneySearch()}
              >
                {t("journeys.creation.journey.search")}
              </Button>
            </Box>
          </div>
          <Container
            sx={{
              display: "flex",
              flexDirection: window.innerWidth < 1000 ? "column" : "row",
              mt: 4,
              alignItems: "center",
            }}
          >
            <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
              <DepartureBoard />
              <DateTimePicker
                ampm={false}
                format=""
                value={moment(datetime)}
                onChange={(value) => setDateTime(value)}
                label={t("journeys.creation.journey.departure")}
                sx={{
                  alignSelf: "flex-start",
                  color: theme.palette.text.primary,
                  p: 1,

                  borderRadius: 1,
                }}
              />
            </Box>
            <Box
              sx={{
                flex: 2,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                mt: window.innerWidth < 1000 ? 4 : 0,
              }}
            >
              <Button
                startIcon={
                  <>
                    <TrainIcon /> <TramIcon />
                  </>
                }
                sx={{
                  color: vehicleSelection.every((val) => val === true)
                    ? theme.palette.getContrastText(theme.palette.primary.main)
                    : theme.palette.text.primary,
                  borderColor: theme.palette.text.primary,
                }}
                variant={
                  vehicleSelection.every((val) => val === true)
                    ? "contained"
                    : "outlined"
                }
                onClick={() => setAllVehicles()}
              >
                {t("journeys.creation.journey.allTrains")}
              </Button>

              <Button
                startIcon={
                  <>
                    <TramIcon />
                  </>
                }
                sx={{
                  color:
                    vehicleSelection
                      .slice(0, 2)
                      .every((val) => val === false) &&
                    vehicleSelection.slice(2).every((val) => val === true)
                      ? theme.palette.getContrastText(
                          theme.palette.primary.main
                        )
                      : theme.palette.text.primary,
                  borderColor: theme.palette.text.primary,
                }}
                variant={
                  vehicleSelection.slice(0, 2).every((val) => val === false) &&
                  vehicleSelection.slice(2).every((val) => val === true)
                    ? "contained"
                    : "outlined"
                }
                onClick={() => setRegionalTrains()}
              >
                {t("journeys.creation.journey.regionalTrains")}
              </Button>

              <Button
                startIcon={
                  <>
                    <MoreVert />
                  </>
                }
                sx={{
                  color: vehicleMenuActive
                    ? theme.palette.getContrastText(theme.palette.primary.main)
                    : theme.palette.text.primary,
                  borderColor: theme.palette.text.primary,
                }}
                onClick={() => setVehicleMenu(!vehicleMenuActive)}
                variant={vehicleMenuActive ? "contained" : "outlined"}
              >
                {t("journeys.creation.journey.custom")}
              </Button>
            </Box>
            {vehicleMenuActive && window.innerWidth < 1000 && (
              <Container sx={{ m: 1, p: 1 }}>
                {renderVehicleButtons([...vehicleSelection])}
              </Container>
            )}
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                mt: window.innerWidth < 1000 ? 4 : 0,
              }}
            >
              <AddIntermediateStopsMenu
                intermediateStops={props.intermediateStops}
                onAddIntermediateStop={(val) => {
                  const newStops = [...props.intermediateStops];
                  const index = props.intermediateStops.findIndex(
                    (v: IntermediateStop) => v.id === val.id
                  );
                  if (index !== -1) {
                    newStops[index] = val;
                  } else {
                    newStops.push(val);
                  }
                  props.setIntermediateStops(newStops);
                }}
                onRemoveIntermediateStop={(index) => {
                  const newStops = [...props.intermediateStops];
                  newStops.splice(index, 1);
                  props.setIntermediateStops(newStops);
                }}
                onUpdateIntermediateStop={(val, index) => {
                  const newStops = [...props.intermediateStops];
                  newStops[index] = val;
                  props.setIntermediateStops(newStops);
                }}
                onClearIntermediateStops={() => {
                  props.setIntermediateStops([]);
                }}
              />
            </Box>
          </Container>
          {vehicleMenuActive && window.innerWidth >= 1000 && (
            <Container>{renderVehicleButtons([...vehicleSelection])}</Container>
          )}
        </CardContent>
      </Card>
      {journeys.length > 0 && (
        <Typography variant="h5" sx={{ mt: 1 }} textAlign="left">
          {t("journeys.creation.journey.possibleJourneys")}
        </Typography>
      )}
      <LoadingWrapper loading={loading}>
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          {journeys.length > 0 ? (
            journeys.map((j: Connection, i) => (
              <div style={{ width: "100%" }} key={i}>
                <JourneyPreview
                  showIconButton
                  key={i}
                  disabled={
                    props.selectedIndex !== -1 && props.selectedIndex !== i
                  }
                  onClick={() => handleJourneyChoose(i)}
                  additionalInformation={[
                    moment
                      .utc(
                        moment.duration(j.duration, "seconds").asMilliseconds()
                      )
                      .format("HH:mm"),
                  ]}
                  journey={{
                    ...j,
                    id: j.id ?? i.toString(),
                    startStation: j.from,
                    endStation: j.to,
                    delay: j.delay ?? 0,
                    trains: j.trains.map(
                      (t: Train) =>
                        ({
                          ...t,
                          line: parseInt(t.number ?? t.id),
                          displayName: t.displayName,
                          type: t.category.toUpperCase(),
                          delayData: t.delay as unknown as IDelayData,
                          portion: Math.round((t.duration / j.duration) * 100),
                          arrival: new Date(t.arrivalTime),
                          departure: new Date(t.departureTime),
                        } as ITrainJourney)
                    ),
                  }}
                />
              </div>
            ))
          ) : (
            <IconText
              icon={showNoContent ? Error : Search}
              text={
                showNoContent
                  ? t("journeys.creation.journey.noJourneys")
                  : t("journeys.creation.journey.searchRecommendation")
              }
            />
          )}
        </div>
      </LoadingWrapper>
    </div>
  );
}
