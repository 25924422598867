import { Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import ConfirmMenu from "../../core/design-system/ConfirmMenu";
import { useTranslation } from "react-i18next";

export default function DeleteJourneyMenu(props: {
  open: boolean;
  onConfirm: () => void;
  setOpen: (val: boolean) => void;
  startName: string;
  endName: string;
  startDate: Date;
  endDate: Date;
}) {
  const { t } = useTranslation();
  return (
    <ConfirmMenu
      confirmLabel={t("journeys.deletion.yes")}
      cancelLabel={t("journeys.deletion.no")}
      description={t("journeys.deletion.description")}
      title={t("journeys.deletion.title")}
      onCancel={() => props.setOpen(false)}
      onConfirm={() => props.onConfirm()}
      otherContent={
        <>
          <Typography>
            {t("journeys.deletion.from")}: <b> {props.startName}</b>{" "}
            {t("journeys.deletion.to")}: <b>{props.endName}</b>
          </Typography>
          <Typography>
            {t("journeys.deletion.start")}:{" "}
            <b>
              {moment(props.startDate).format("DD.MM.YYYY HH:mm").toString()}{" "}
            </b>
            {t("journeys.deletion.end")}:{" "}
            <b>
              {" "}
              {moment(props.endDate).format("DD.MM.YYYY HH:mm").toString()}
            </b>
          </Typography>
        </>
      }
      open={props.open}
    />
  );
}
