import { IntermediateStop as IntermediateStopType } from "@jozys/db-api-wrapper/dist/src/connection";
import { Add } from "@mui/icons-material";
import {
  Box,
  Container,
  Divider,
  Fab,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import IntermediateStop from "../design-system/IntermediateStop";

export default function IntermediateStops(props: {
  intermediateStops: IntermediateStopType[];
  onAddIntermediateStop: (newStop: IntermediateStopType) => void;
  onRemoveIntermediateStop: (index: number) => void;
  onUpdateIntermediateStop: (
    updatedStop: IntermediateStopType,
    index: number
  ) => void;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Container>
      <Typography variant="h5" sx={{ mt: 4 }}>
        {t(`journeys.creation.journey.intermediateStops.title`)}
      </Typography>

      <Typography sx={{ mt: 4 }}>
        {t(`journeys.creation.journey.intermediateStops.detailed`)}
      </Typography>
      {props.intermediateStops.map((stop, index) => (
        <Container sx={{ mt: 1 }}>
          <IntermediateStop
            index={index}
            intermediastop={stop}
            onUpdate={(v, i) => {
              props.onUpdateIntermediateStop(v, i);
            }}
            onRemove={(i) => props.onRemoveIntermediateStop(i)}
          />
          <Divider />
        </Container>
      ))}
      <Box sx={{ mt: 4 }}>
        <Tooltip
          title={
            props.intermediateStops.length >= 2
              ? t("journeys.creation.journey.intermediateStops.maximum")
              : t(`journeys.creation.journey.intermediateStops.add`)
          }
        >
          <Fab
            sx={{
              backgroundColor:
                props.intermediateStops.length >= 2
                  ? theme.palette.action.disabled
                  : theme.palette.primary.main,
            }}
            variant="extended"
            onClick={() => {
              if (props.intermediateStops.length < 2) {
                props.onAddIntermediateStop({ id: "", minutes: 5 });
              }
            }}
          >
            <Add
              sx={{
                color: theme.palette.getContrastText(
                  props.intermediateStops.length >= 2
                    ? theme.palette.action.disabled
                    : theme.palette.primary.main
                ),
              }}
            />
          </Fab>
        </Tooltip>
      </Box>
    </Container>
  );
}
