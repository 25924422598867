import { IJourneyDetailed } from "@jozys/db-delay-types";
import { Error } from "@mui/icons-material";
import {
  Breadcrumbs,
  Card,
  Container,
  Fab,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import LoadingWrapper from "../../core/components/LoadingWrapper";
import IconText from "../../core/design-system/IconText";
import Iternary from "../../create-journey/design-system/Iternary";
import useJourney from "../hooks/useJourney";
import DelayCard from "./DelayCard";
import DeleteJourneyMenu from "./DeleteJourneyMenu";
import JourneyHeader from "./JourneyHeader";

export default function JourneyDetailed() {
  const params = useParams();
  const location = useLocation();
  const theme = useTheme();
  const { t } = useTranslation();
  const { getJourneyById, deleteJourneyById } = useJourney();
  const [loading, setLoading] = useState<boolean>(true);
  const [journey, setJourney] = useState<IJourneyDetailed>();
  const [deleteMenuOpen, setDeleteMenuOpen] = useState<boolean>(false);

  const fetchJourney = () => {
    if (params.journeyId != null) {
      setJourney(undefined);
      setLoading(true);
      getJourneyById(params.journeyId)
        .then((res) => {
          setJourney(res);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };

  const handleDelete = () => {
    if (journey != null) {
      setLoading(true);
      setDeleteMenuOpen(false);
      deleteJourneyById(journey.id)
        .then((success: boolean) => {
          if (success) {
            window.location.assign("/journeys");
          }
        })
        .catch((e) => {
          window.alert("Failed to delete journey");
        });
    }
  };

  React.useEffect(() => {
    fetchJourney();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <LoadingWrapper loading={loading}>
      {!loading && journey != null ? (
        <Container
          sx={{
            position: "relative",
            height: "100%",
            pb: 10,
          }}
        >
          <Breadcrumbs>
            <Link style={{ color: theme.palette.secondary.main }} to={"/"}>
              {t("home.title")}
            </Link>
            <Link
              style={{ color: theme.palette.secondary.main }}
              to={`/journeys${
                location.state &&
                location.state?.page != null &&
                location.state?.page > 0
                  ? `?page=${location.state.page}`
                  : ""
              }`}
            >
              {t("home.pages.journeys")}
            </Link>
            <Typography>{t("journeys.detailed.title")}</Typography>
          </Breadcrumbs>
          <Typography
            fontWeight={"bold"}
            textAlign={"left"}
            variant="h5"
            sx={{
              mt: 1,
            }}
          >
            {t("journeys.detailed.current")}
          </Typography>
          <JourneyHeader
            onEdit={() => {
              window.alert("This feature is not implemented yet");
            }}
            onDelete={() => setDeleteMenuOpen(true)}
            journey={journey as IJourneyDetailed}
          />

          <Typography
            fontWeight={"bold"}
            textAlign={"left"}
            variant="h5"
            sx={{ mt: 1 }}
          >
            {t("journeys.detailed.info")}
          </Typography>
          <DelayCard journey={journey} />
          <Typography
            fontWeight={"bold"}
            textAlign={"left"}
            variant="h5"
            sx={{ mt: 1 }}
          >
            {t("journeys.detailed.iternary")}
          </Typography>
          <Card sx={{ background: "transparent" }}>
            <Iternary journey={journey as IJourneyDetailed} />
          </Card>
          <Fab
            sx={{ position: "absolute", right: 10, bottom: 10 }}
            variant="extended"
            color="secondary"
            onClick={() => window.alert("This feature is not implemented yet")}
          >
            {t("journeys.alternative.add")}
          </Fab>
          <DeleteJourneyMenu
            endName={journey.endStation.name}
            startName={journey.startStation.name}
            endDate={journey.endDate}
            startDate={journey.startDate}
            onConfirm={() => {
              handleDelete();
              setDeleteMenuOpen(false);
            }}
            setOpen={setDeleteMenuOpen}
            open={deleteMenuOpen}
          />
        </Container>
      ) : (
        <IconText icon={Error} text="Not found" />
      )}
    </LoadingWrapper>
  );
}
