import {
  IJourneyDetailed,
  IJourneyPreview,
  ITrainJourney,
  ITrainJourneyPreview,
} from "@jozys/db-delay-types";

export const _reverseInJourneys = (journeys: IJourneyPreview[]) => {
  return journeys.map((j) => _reverseJourney(j));
};

export const _reverseJourney = (
  j: IJourneyPreview | IJourneyDetailed
): IJourneyPreview | IJourneyDetailed => {
  return {
    ...j,
    startStation: _reverseString(
      typeof j.startStation === "string" ? j.startStation : j.startStation.name
    ),
    endStation: _reverseString(
      typeof j.endStation === "string" ? j.endStation : j.endStation.name
    ),
    trains: j.trains.map((t) => _reverseTrain(t) as ITrainJourneyPreview),
  };
};

export const _reverseTrain = (
  t: ITrainJourneyPreview | ITrainJourney
): ITrainJourneyPreview | ITrainJourney => {
  return {
    ...t,
    destination: _reverseString(t.destination ?? ""),
    operator: _reverseString(t.operator ?? ""),
  };
};

const _reverseString = (s: string) => {
  return s.split("").reverse().join("");
};
