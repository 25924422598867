import React, { createContext } from "react";
import { JourneyCategory, JourneyOwner } from "../interfaces/JourneyConfig";
import {
  JourneyFilterContextDefault,
  JourneyFilterContextType,
} from "../interfaces/JourneyFilterContext";

export const JourneyFilterContext = createContext<JourneyFilterContextType>(
  JourneyFilterContextDefault
);

export default function JourneyFilterProvider(props: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const [owner, setOwner] = React.useState<JourneyOwner>(JourneyOwner.ALL);
  const [category, setCategory] = React.useState<JourneyCategory>(
    JourneyCategory.ALL
  );

  const setJourneyCategory = (category: JourneyCategory) => {
    setCategory(category);
    localStorage.setItem("journeyCategory", category);
  };

  const setJourneyOwnerType = (owner: JourneyOwner) => {
    setOwner(owner);
    localStorage.setItem("journeyOwner", owner);
  };

  React.useEffect(() => {
    const storedCategory = localStorage.getItem("journeyCategory");
    if (storedCategory) {
      setCategory(storedCategory as JourneyCategory);
    }

    const storedOwner = localStorage.getItem("journeyOwner");
    if (storedOwner) {
      setOwner(storedOwner as JourneyOwner);
    }
  }, []);

  return (
    <JourneyFilterContext.Provider
      value={{
        getOwnerType: () => owner,
        getCategory: () => category,
        setCategory: setJourneyCategory,
        setOwnerType: setJourneyOwnerType,
      }}
    >
      {props.children}
    </JourneyFilterContext.Provider>
  );
}
