import React, { createContext, useState } from "react";
import { UserContextDefault, UserContextType } from "../interfaces/UserContext";
import useAuth from "./useAuth";
import { AxiosResponse } from "axios";
import { IUser } from "@jozys/db-delay-types";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

export const UserContext = createContext<UserContextType>(UserContextDefault);

export default function UserProvider(props: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const [user, setUser] = useState<IUser>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { i18n } = useTranslation();
  const auth = useAuth();

  /**
   * Fetches the user data from the backend using the auth data from ory
   */
  const fetchAuthorizedUser = async () => {
    setLoading(true);
    if (auth.getToken() == null) return;
    const response: AxiosResponse<IUser> = await global.axios.get(`/user`, {
      headers: { Authorization: `Bearer ${auth.getToken()}` },
    });
    setUser(response.data);
    setLoading(false);
    i18n.changeLanguage(response.data.language);
    moment.locale(_parseCode(response.data.language));
  };

  /**
   * Fetches a user by its id from the backend server
   * @param id the uuid from the user
   * @returns
   */
  const getUserById = async (id: string) => {
    const response = await global.axios.get<AxiosResponse<IUser>>(
      `/user/${id}`,
      {
        headers: { Authorization: `Bearer ${auth.getToken()}` },
      }
    );
    return response.data.data as IUser;
  };

  const updateUser = async (data: IUser): Promise<IUser> => {
    const response = await global.axios.patch<IUser>(`/user`, data, {
      headers: { Authorization: `Bearer ${auth.getToken()}` },
    });
    if (response.status === 200 && response.data) {
      setUser(response.data);
      i18n.changeLanguage(response.data.language);
      moment.locale(_parseCode(response.data.language));
    }
    return response.data;
  };

  /**
   * A function to parse the language code to a valid language code
   * This function is required, because this tool has different language codes, because it has exotics languages like ed or bd
   * @param code
   */
  const _parseCode = (code?: string) => {
    if (!code) return "en";
    switch (code.toLowerCase()) {
      case "ed":
      case "bd":
      case "sß":
        return "de";
      case "zh":
        return "zh_cn";
      default:
        return code;
    }
  };

  React.useEffect(() => {
    fetchAuthorizedUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.getToken()]);

  return (
    <UserContext.Provider
      value={{
        fetchAuthorizedUser,
        updateUser,
        user,
        getUserById,
        isLoading,
      }}
    >
      <LocalizationProvider
        adapterLocale={user?.language ?? "en"}
        dateAdapter={AdapterMoment}
      >
        {props.children}
      </LocalizationProvider>
    </UserContext.Provider>
  );
}
