import { IntermediateStop as IntermediateStopType } from "@jozys/db-api-wrapper/dist/src/connection";
import React from "react";
import Button from "../../core/design-system/Button";
import IntermediateStopsMenu from "../design-system/IntermediateStopsMenu";
import { useTranslation } from "react-i18next";
import {AddLocation} from "@mui/icons-material";

export default function AddIntermediateStopsMenu(props: {
  intermediateStops: IntermediateStopType[];
  onAddIntermediateStop: (newStop: IntermediateStopType) => void;
  onRemoveIntermediateStop: (index: number) => void;
  onUpdateIntermediateStop: (
    updatedStop: IntermediateStopType,
    index: number
  ) => void;
  onClearIntermediateStops: () => void;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Button
        sx={{ height: 50, justifySelf: "flex-end" ,}}
        variant="contained"
        startIcon={<AddLocation />}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        {props.intermediateStops.length > 0
          ? `${props.intermediateStops.length} ${t(
              `journeys.creation.journey.intermediateStops.configured`
            )}`
          : t(`journeys.creation.journey.intermediateStops.add`)}
      </Button>
      <IntermediateStopsMenu
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        {...props}
      />
    </>
  );
}
