import axios from "axios";
import i18n from "i18next";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/fr";
import "moment/locale/zh-cn";
import "moment/locale/nl";
import { initReactI18next } from "react-i18next";
import "./App.css";
import AuthProvider from "./modules/auth/hooks/AuthProvider";
import UserProvider from "./modules/auth/hooks/UserProvider";
import Router from "./modules/core/components/Router";
import JourneyProvider from "./modules/journey/hooks/JourneyProvider";
import de from "./modules/language/locales/de.json";
import en from "./modules/language/locales/en.json";
import fr from "./modules/language/locales/fr.json";

// just for fun
import lb from "./modules/language/locales/lb.json";
import nl from "./modules/language/locales/nl.json";
import zh from "./modules/language/locales/zh.json";

// esoteric
import bd from "./modules/language/locales/bd.json";
import ed from "./modules/language/locales/ed.json";
import sß from "./modules/language/locales/sß.json";

import StatisticsProvider from "./modules/statistics/hooks/StatisticsProvider";
import moment from "moment-timezone";
import ThemeProvider from "./modules/core/theme/hooks/ThemeProvider";

import { useTheme } from "@mui/material";

i18n.use(initReactI18next).init({
  fallbackLng: "en",

  resources: {
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
    fr: {
      translation: fr,
    },
    zh: {
      translation: zh,
    },
    lb: {
      translation: lb,
    },
    nl: {
      translation: nl,
    },
    bd: {
      translation: bd,
    },
    ed: {
      translation: ed,
    },
    sß: {
      translation: sß,
    },
  },
});

function App() {
  const axiosInstance = axios.create();
  axiosInstance.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
  axiosInstance.defaults.timeout = 12000;
  axiosInstance.defaults.withCredentials = true;
  global.axios = axiosInstance;
  moment.tz.setDefault("Europe/Berlin");
  const theme = useTheme();
  return (
    <ThemeProvider>
      <div
        className="App"
        style={{
          background: theme.palette.background.default,
        }}
      >
        <AuthProvider>
          <UserProvider>
            <JourneyProvider owner="ALL" category="ALL">
              <StatisticsProvider>
                <Router />
              </StatisticsProvider>
            </JourneyProvider>
          </UserProvider>
        </AuthProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
