import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";

export default function ConfirmMenu(props: {
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
  title: string;
  description: string;
  confirmLabel: string;
  cancelLabel: string;
  otherContent?: React.ReactNode | React.ReactNode[];
}) {
  return (
    <Modal
      open={props.open}
      onClose={() => props.onCancel()}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card>
        <CardHeader title={props.title} />
        <CardContent>
          <Typography variant="body1">{props.description}</Typography>
          {props.otherContent}
        </CardContent>
        <CardActions>
          <Button variant="contained" onClick={props.onConfirm}>
            {props.confirmLabel}
          </Button>
          <Button onClick={props.onCancel}>{props.cancelLabel}</Button>
        </CardActions>
      </Card>
    </Modal>
  );
}
