import { Box, InputProps, Typography } from "@mui/material";
import { uniq } from "lodash";
import React from "react";
import useAuth from "../../auth/hooks/useAuth";
import Avatar from "../../core/design-system/UserAvatar";
import GenericAddInput from "../design-system/GenericAddInput";

export type Other = string;

export default function AddOthers(
  props: InputProps & {
    onValueChange: (event: React.SyntheticEvent, value: Other | null) => void;
    label: string;
    addOthers: (others: string[]) => void;
    others: string[];
  }
) {
  const auth = useAuth();

  const removeOther = (index: number) => {
    const updateOthers = [...props.others];
    updateOthers.splice(index, 1);
    props.addOthers(updateOthers);
  };

  const onValueChange = async (search: string) => {
    if (search === "" || search.length < 3) {
      return [];
    }
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/user/others/find?name=${search}`,
      { headers: { Authorization: `Bearer ${auth.getToken()}` } }
    );
    return response.data.filter((v: string) => !props.others.includes(v)) ?? [];
  };

  return (
    <GenericAddInput<string>
      getAutoCompleteOptions={onValueChange}
      freeSolo
      removeSelected={removeOther}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key}
            component={"li"}
            sx={{ display: "flex", alignItems: "center" }}
            {...optionProps}
          >
            <Avatar key={option} username={option} />
            <Typography sx={{ ml: 1 }}>{option}</Typography>
          </Box>
        );
      }}
      getKey={(o) => o}
      getLabel={(o) => o}
      onSelect={(v) => {
        if (v != null) {
          props.others.push(v);
          props.addOthers(uniq(props.others));
        }
      }}
      selected={props.others ?? []}
    ></GenericAddInput>
  );
}
