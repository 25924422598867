import { IntermediateStop as IntermediateStopType } from "@jozys/db-api-wrapper/dist/src/connection";
import React from "react";
import StationSearch from "../../station/components/StationSearch";
import { Box, Button, Container, TextField, useTheme } from "@mui/material";
import { Remove, Train } from "@mui/icons-material";
import { TimeIcon } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";

export default function IntermediateStop(props: {
  intermediastop: IntermediateStopType;
  index: number;
  onUpdate: (updatedStop: IntermediateStopType, index: number) => void;
  onRemove: (index: number) => void;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: window.innerWidth < 500 ? "column" : "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Train />
          <StationSearch
            value={{
              name: props.intermediastop.name ?? "",
              id: props.intermediastop.id,
            }}
            label={t("journeys.creation.journey.intermediateStops.title")}
            onValueChange={(_e, v) => {
              props.onUpdate(
                {
                  ...props.intermediastop,
                  id: v?.id ?? "",
                  name: v?.name ?? "",
                },
                props.index
              );
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "space-between",
          }}
        >
          <TimeIcon />
          <TextField
            value={props.intermediastop.minutes ?? 0}
            sx={{
              bgcolor: theme.palette.background.paper,
              borderRadius: 1,
              pb: 0.5,
              ml: 1,
            }}
            type="number"
            placeholder="Minutes"
            title="Minutes"
            label="Minutes"
            variant="standard"
            size="small"
            onChange={(e) => {
              props.onUpdate(
                {
                  ...props.intermediastop,
                  minutes: parseInt(e.target.value),
                },
                props.index
              );
            }}
          ></TextField>
        </Box>
      </Container>
      <Button onClick={() => props.onRemove(props.index)}>
        <Remove />
      </Button>
    </Container>
  );
}
