export enum JourneyOwner {
  ALL = "ALL",
  ALONE = "ALONE",
  SHARED = "SHARED",
}

export enum JourneyCategory {
  ALL = "ALL",
  LONG_DISTANCE = "LONG_DISTANCE",
  REGIONAL = "REGIONAL",
}
