import {
  ITrainJourney,
  ITrainJourneyPreview,
  TrainType,
} from "@jozys/db-delay-types";
import { Container } from "@mui/material";
import Train from "./Train";

export interface TrainConnectionProps {
  trains: ITrainJourneyPreview[] | ITrainJourney[];
}

export default function TrainConnection(props: TrainConnectionProps) {
  return (
    <Container
      sx={{
        display: "flex",
        width: "100%",
        overflowY: "auto",
        pointerEvents: "",
        scrollbarColor: "darkgray transparent",
        pb: window.innerWidth < 1000 ? 2 : 0,
        "::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "inherit",
          boxShadow: "none",
          transform: "none",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "lightgray",
        },
        "::-webkit-scrollbar": {
          width: "10px",
        },
        scrollbarWidth: "thin", // Firefox
      }}
    >
      {props.trains.map((t, i) => (
        <Train
          {...t}
          key={`${t.type} ${t.line}`}
          displayName={t.displayName ?? `${t.type} ${t.line}`}
          portion={t.portion ?? 100}
          start={i === 0}
          type={t.type.toUpperCase() as TrainType}
          end={i === props.trains.length - 1}
        />
      ))}
    </Container>
  );
}
