import {
  AvatarProps,
  Avatar as MuiAvatar,
  Skeleton,
  useTheme,
} from "@mui/material";
import React from "react";

export function stringToColor(string: string) {
  let hash = 0;
  let i;

   
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function getInitials(name: string) {
  const splitted = name.split(" ");
  if (splitted.length <= 1 || splitted.length > 2) {
    return splitted[0][0] + splitted[0][1];
  } else {
    return splitted[0][0] + splitted[1][0];
  }
}

function stringAvatar(
  name: string,
  getTextContrast: (background: string) => string
) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      color: getTextContrast(stringToColor(name)),
    },
    children: `${getInitials(name)}`,
  };
}

export interface UserAvatarProps extends AvatarProps {
  lastname?: string;
  firstname?: string;
  username: string;
  isLoading: boolean;
}

export default function Avatar(props: UserAvatarProps) {
  const theme = useTheme();
  return (
    <>
      {props.isLoading ? (
        <Skeleton variant="circular" width={40} height={40} />
      ) : (
        <MuiAvatar
          {...stringAvatar(
            props.firstname && props.lastname
              ? `${props.firstname} ${props.lastname}`
              : props.username,
            theme.palette.getContrastText
          )}
          {...props}
        />
      )}
    </>
  );
}
