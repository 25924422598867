import {
  Add,
  ContentPaste,
  Functions,
  RailwayAlert,
  Timelapse,
  Warning,
} from "@mui/icons-material";
import { Container, Fab, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LoadingWrapper from "../../core/components/LoadingWrapper";
import IconText from "../../core/design-system/IconText";
import { getPunctialityColor } from "../../core/utils/statistics";
import useJourney from "../../journey/hooks/useJourney";
import useStatistics from "../../statistics/hooks/useStatistics";
import { IStatistic } from "../interface/IStatistic";
import LastJourney from "./LastJourney";
import StatisticsPreview from "./StatisticsPreview";
import { parseSize } from "../../create-journey/utils/size";

export default function Home() {
  const journey = useJourney();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { simpleStatistics, getSimpleStatistics } = useStatistics();
  const [stats, setStats] = React.useState<IStatistic<string>[]>([]);
  const navigate = useNavigate();
  const theme = useTheme();

  const initialFetch = async () => {
    setLoading(true);
    await getSimpleStatistics();
    await journey.fetchJourneys(0);
    setLoading(false);
  };

  React.useEffect(() => {
    initialFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setStats([
      {
        key: t("statistics.punctuality.title"),
        value: `${simpleStatistics.punctuality}%`,
        //@ts-ignore
        icon: (
          <Timelapse
            sx={{
              color: getPunctialityColor(
                simpleStatistics.punctuality,
                theme.palette
              ),
            }}
          />
        ),
        color: getPunctialityColor(simpleStatistics.punctuality, theme.palette),
      },
      {
        key: t("statistics.journeys.title"),
        value: simpleStatistics.amountOfJourneys.toString() ?? 0,
        icon: <Functions />,
      },
      {
        key: t("statistics.delay.average"),
        value: `${simpleStatistics.averageDelay.minutes}min ${simpleStatistics.averageDelay.seconds}s`,
        icon: <RailwayAlert />,
        color:
          simpleStatistics.averageDelay.minutes > 5
            ? theme.palette.error.main
            : theme.palette.success.main,
      },
      {
        key: t("statistics.delay.highest"),
        value: `${simpleStatistics.highestDelay}min`,
        icon: <Warning />,
        color:
          simpleStatistics.highestDelay > 5
            ? theme.palette.error.main
            : theme.palette.success.main,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simpleStatistics]);
  return (
    <div
      style={{
        width: "100%",
        background: theme.palette.background.paper,
      }}
    >
      <Typography
        sx={{ fontSize: parseSize("large", 1.25) }}
        color={"textPrimary"}
        variant="h2"
      >
        {t("home.overview")}{" "}
      </Typography>
      <LoadingWrapper key={"home.loading"} loading={loading}>
        <div
          key={"home.card"}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {journey.getLastJourney() ? (
            <div
              key={"home.lastJourneyContainer"}
              style={{
                width: window.innerWidth < 500 ? "90%" : "80%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingBottom: "5%",
              }}
            >
              <StatisticsPreview statistics={stats} />
              <LastJourney journey={journey.getLastJourney()} />
            </div>
          ) : (
            <Container style={{ position: "relative" }}>
              <IconText
                icon={ContentPaste}
                text={t("journeys.creation.journey.noPersonalJourneys")}
              />
            </Container>
          )}
          <Fab
            color="secondary"
            sx={{ position: "absolute", right: "20%", bottom: "0%" }}
            onClick={() => navigate("/journeys/create")}
            aria-label="Add new journey"
            key="Add-Journey-FAB"
          >
            <Add />
          </Fab>
        </div>
      </LoadingWrapper>
    </div>
  );
}
