import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Menu,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { Category } from "./Journeys";
import { useTranslation } from "react-i18next";
import { JourneyCategory } from "../interfaces/JourneyConfig";

export default function CategoryMenu(props: {
  open: boolean;
  onChange: (val: JourneyCategory) => void;
  onClose: () => void;
  selectedCategory: Category;
  anchorElement: HTMLDivElement | undefined;
}) {
  const { t } = useTranslation();
  return (
    <Menu
      open={props.open}
      onClose={() => props.onClose()}
      anchorEl={props.anchorElement}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <FormControl>
        <FormLabel>
          <Typography variant="h6" align="center">
            {t("journeys.configuration.title")}
          </Typography>
        </FormLabel>
        <RadioGroup
          onChange={(event) => {
            //@ts-ignore
            props.onChange(event.target.value);
          }}
        >
          {Object.values(JourneyCategory).map((category) => (
            <FormControlLabel
              sx={{ ml: 1 }}
              label={t(`journeys.configuration.types.${category}`)}
              value={category}
              control={<Radio />}
              checked={props.selectedCategory === category}
              key={category}
            ></FormControlLabel>
          ))}
        </RadioGroup>
      </FormControl>
    </Menu>
  );
}
