import { JourneyCategory, JourneyOwner } from "./JourneyConfig";

export type JourneyFilterContextType = {
  getOwnerType(): JourneyOwner;
  getCategory(): JourneyCategory;
  setOwnerType(JourneyOwner: JourneyOwner): void;
  setCategory(category: string): void;
};

export const JourneyFilterContextDefault: JourneyFilterContextType = {
  getOwnerType: () => JourneyOwner.ALL,
  getCategory: () => JourneyCategory.ALL,
  setOwnerType: () => null,
  setCategory: () => null,
};
