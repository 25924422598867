import {
  DirectionsBoat,
  DirectionsBus,
  DirectionsTransitFilled,
  LocalTaxi,
  Subway,
  Train,
  Tram
} from "@mui/icons-material";


const VehicleSelection = {
  "ICE" : {
    "name": "train.ICE",
    "icon": Train
  },
  "EC_IC" : {
    "name": "train.EC_IC",
    "icon": DirectionsTransitFilled
  },
  "IR" : {
    "name": "train.IR",
    "icon": "F"
  },
  "REGIONAL" : {
    "name": "train.REGIONAL",
    "icon": "R"
  },
  "SBAHN" : {
    "name": "train.SBAHN",
    "icon": "S"
  },
  "BUS" : {
    "name": "train.BUS",
    "icon": DirectionsBus
  },
  "SCHIFF" : {
    "name": "train.SCHIFF",
    "icon": DirectionsBoat
  },
  "UBAHN" : {
    "name": "train.UBAHN",
    "icon": Subway
  },
  "TRAM" : {
    "name": "train.TRAM",
    "icon": Tram
  },
  "ANRUFPFLICHTIG" : {
    "name": "train.ANRUFPFLICHTIG",
    "icon": LocalTaxi
  },
}
export default VehicleSelection