import { IJourneyPreview } from "@jozys/db-delay-types";
import { Forward } from "@mui/icons-material";
import {
  Card,
  Container,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import TrainConnection from "../../core/design-system/TrainConnection";
import moment from "moment";
import { parseSize } from "../../create-journey/utils/size";

export interface JourneyProps {
  journey: IJourneyPreview;
  additionalInformation?: string[];
  onClick?: React.MouseEventHandler<HTMLButtonElement | undefined>;
  disabled?: boolean;
  showDate?: boolean;
  showIconButton?: boolean;
}
export default function JourneyPreview(props: JourneyProps) {
  const theme = useTheme();
  return (
    <Card
      elevation={0}
      key={props.journey.id}
      sx={{
        display: "flex",
        minHeight: "11.0vh",
        overflow: "clip",
        mt: 1,
        background: props.disabled
          ? theme.palette.background.paper
          : theme.palette.background.default,
      }}
    >
      <div
        style={{
          display: "flex",
          height: "auto",
          width: "100%",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "space-between",
            flexDirection: "column",
            flex: 24,
            maxWidth: "90%",
          }}
        >
          <Container
            maxWidth={false}
            sx={{
              display: "flex",
            }}
          >
            <Typography
              textAlign={"left"}
              sx={{
                flex: 1,
                fontSize:
                  window.innerWidth < 600
                    ? parseSize("medium", 0.5)
                    : parseSize("medium", 0.75),
              }}
            >
              <b>
                {props.showDate
                  ? moment(props.journey.startDate).format("DD.MM.YYYY") + " | "
                  : ""}
                {moment(props.journey.startDate).format("HH:mm").toString() +
                  " - " +
                  moment(props.journey.endDate).format("HH:mm")}
              </b>
              {props.additionalInformation?.map((text) => ` | ${text}h`)}
            </Typography>
            <Typography
              sx={{
                flex: 1,
                color:
                  props.journey.delay > 5
                    ? theme.palette.error.main
                    : theme.palette.success.main,
                fontWeight: "bold",
                fontSize:
                  window.innerWidth < 500
                    ? parseSize("medium", 0.5)
                    : parseSize("medium", 0.75),
              }}
              textAlign={"right"}
            >
              {props.journey?.delay >= 0
                ? `+${props.journey.delay}`
                : `${props.journey.delay}`}
            </Typography>
          </Container>

          <TrainConnection
            trains={
              props.journey?.trains.filter(
                (t) => t.type.toString() !== "SONSTIGE"
              ) ?? []
            }
          />
          <Container sx={{ display: "flex", alignContent: "space-around" }}>
            <Typography
              textAlign={"left"}
              sx={{
                flex: 1,
                fontSize:
                  window.innerWidth < 500
                    ? parseSize("medium", 0.5)
                    : parseSize("medium", 0.75),
              }}
            >
              {props.journey.startStation}
            </Typography>
            <Typography
              textAlign={"right"}
              sx={{
                flex: 1,
                fontSize:
                  window.innerWidth < 500
                    ? parseSize("medium", 0.5)
                    : parseSize("medium", 0.75),
              }}
            >
              {props.journey.endStation}
            </Typography>
          </Container>
        </Container>
        {props.showIconButton && (
          <IconButton
            onClick={props.onClick}
            sx={{ display: "flex", alignSelf: "center", flex: 1 }}
          >
            <Forward sx={{ flex: 1 }} />
          </IconButton>
        )}
      </div>
    </Card>
  );
}
