import { Divider, Menu, MenuItem, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAuth from "../../auth/hooks/useAuth";
import useUser from "../../auth/hooks/useUser";

export default function AvatarMenu(props: {
  handleClose: () => void;
  anchorElement: HTMLDivElement | undefined;
}) {
  const { user, isLoading } = useUser();
  const auth = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Menu
      sx={{ mt: "45px" }}
      anchorEl={props.anchorElement}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      disableScrollLock
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(props.anchorElement)}
      onClose={props.handleClose}
    >
      {isLoading ? (
        <Skeleton variant="text" />
      ) : (
        <MenuItem>{user?.firstname + " " + user?.lastname}</MenuItem>
      )}
      <MenuItem onClick={() => navigate("/settings")}>
        {t("settings.title")}
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => auth.signOut()}>{t("settings.logout")}</MenuItem>
    </Menu>
  );
}
