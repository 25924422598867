import { IUser } from "@jozys/db-delay-types";

export type UserContextType = {
  user: IUser | undefined;
  isLoading: boolean;
  fetchAuthorizedUser: () => void;
  getUserById: (id: string) => Promise<IUser>;
  updateUser: (data: IUser) => Promise<IUser>;
};

export const UserContextDefault: UserContextType = {
  fetchAuthorizedUser: () => new Promise(() => {}),
  getUserById: () => new Promise(() => {}),
  updateUser: () => new Promise(() => {}),
  user: undefined,
  isLoading: true,
};
