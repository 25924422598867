import { IntermediateStop, Connection } from "@jozys/db-api-wrapper";
import VehicleSelection from "../../create-journey/components/VehicleSelection";

const searchDBJourney = async (
  from: string,
  to: string,
  date: Date,
  token: string,
  intermediateStops?: IntermediateStop[],
  minTransferTime?: number,
  trainTypes?: boolean[]
): Promise<Connection[]> => {
  let trainTypesObject: any = {};
  Object.keys(VehicleSelection).forEach((key, i) => {
    if (trainTypes) {
      trainTypesObject[key] = trainTypes[i];
    } else {
      trainTypesObject[key] = true;
    }
  });

  const response = await axios.post(
    `/db/journeys`,
    {
      from,
      to,
      date: date.toISOString(),
      intermediateStops,
      minTransferTime,
      trainTypes: trainTypesObject,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data ?? [];
};
export default searchDBJourney;
