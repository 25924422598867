import { Button, Card, CardActions, Modal, useTheme } from "@mui/material";
import React from "react";
import IntermediateStops from "../components/IntermediateStops";
import { IntermediateStop as IntermediateStopType } from "@jozys/db-api-wrapper/dist/src/connection";
import { useTranslation } from "react-i18next";

export default function IntermediateStopsMenu(props: {
  open: boolean;
  onClose: () => void;
  onAddIntermediateStop: (newStop: IntermediateStopType) => void;
  onRemoveIntermediateStop: (index: number) => void;
  onUpdateIntermediateStop: (
    updatedStop: IntermediateStopType,
    index: number
  ) => void;
  onClearIntermediateStops: () => void;
  intermediateStops: IntermediateStopType[];
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleEmptyCheck = () => {
    if (props.intermediateStops.some((stop) => stop.id === "")) {
      const vals = props.intermediateStops.filter((stop) => stop.id === "");
      for (const val of vals) {
        props.onRemoveIntermediateStop(props.intermediateStops.indexOf(val));
      }
    }
  };
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          width: window.innerWidth < 1000 ? "90%" : "50%",
          minHeight: "35%",
          background: theme.palette.background.paper,
        }}
      >
        <IntermediateStops {...props} />

        <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => {
              props.onClose();
            }}
          >
            {t(`journeys.creation.journey.intermediateStops.cancel`)}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleEmptyCheck();
              props.onClose();
            }}
          >
            {t(`journeys.creation.journey.intermediateStops.save`)}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
}
